import { useCallback } from 'react'
import { useHistory, usePathname } from 'router'
import { useMutation } from 'apollo-client'
import { openNotification } from 'notifications'
import links from 'links'
import { GraphQLError, processError, UserError } from 'helpers'
import { useSubscription } from 'modules/subscription'

import unskipSubscriptionQuery from './unskipSubscription.graphql'

import messages from './messages'


const useUnskipSubscription = ({ placement, isRedirectRequired: defaultValue }: { placement: string, isRedirectRequired?: boolean }) => {
  const history = useHistory()
  const pathname = usePathname()
  const [ submitQuery, { isFetching: isSubmitting } ] = useMutation(unskipSubscriptionQuery, {
    refetchQueries: [ 'Subscription' ],
    awaitRefetchQueries: true,
  })
  const { subscription, isFetching } = useSubscription()

  const monthsDuration = subscription?.activeHold?.monthsDuration

  const isRedirectRequired = defaultValue ?? pathname !== links.profile.subscriptionStatus

  const handleUnskipSubscription = useCallback(async () => {
    try {
      const result = await submitQuery({
        variables: {
          input: {
            metadata: {
              placement,
            },
          },
        },
      })

      if (result.errors) {
        throw new GraphQLError(result.errors)
      }

      const { error, data } = result.data.subscriptionUnskip

      if (error) {
        if (error.__typename === 'BusinessError' && error.businessErrorCode === 'PAYMENT_METHOD_NOT_FOUND') {
          history.push(links.cardUpdate)
        }

        throw new UserError(error)
      }

      const premiumProduct = data?.premiumProductInBatchNotification?.product

      if (premiumProduct) {
        // TODO display WelcomeBack modal with product - added on 1/26/21 by @mikediam
        return
      }

      if (isRedirectRequired) {
        history.push(links.profile.subscriptionStatus)
      }

      openNotification('success', messages.successNotification)
    }
    catch (error) {
      processError(error)
    }
  }, [ submitQuery, placement, history, isRedirectRequired ])

  return {
    monthsDuration,
    handleUnskipSubscription,
    isSubmitting,
    isFetching,
  }
}


export default useUnskipSubscription
