export default {
  successNotification: {
    title: {
      en: 'Success!',
    },
    text: {
      en: 'Your subscription has resumed. Modify your next order within 12 hours, before we ship your delivery.',
    },
  },
}
